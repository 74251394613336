<template>
  <div
    :class="{ 'add-mode': isAddMode }"
    @keydown.esc="onEsc()"
    tabindex="0"
    @keydown.n="onAddModeClick()"
  >
    <AddPlace v-show="$isDev" @toggle="onAddModeClick()"></AddPlace>
    <div
      tabindex="0"
      role="button"
      class="findme absolute z-20"
      aria-label="Hitta mig"
      @keypress.enter="locate()"
    >
      <div
        @click="locate()"
        class="bg-white border-gray-800 border-opacity-25 padding-box rounded border-2 shadow-lg cursor-pointer hover:bg-yellow-100 transition-all duration-200"
      >
        <font-awesome-icon class="text-gray-700" icon="crosshairs" />
      </div>
    </div>
    <div
      tabindex="0"
      class="findme absolute z-30 mt-10"
      role="button"
      aria-label="Visa kategorifilter"
      aria-controls="category-picker"
      @keypress.enter="
        // prettier-ignore
        pickerActive = !pickerActive;
        send('ESC')
      "
    >
      <div
        @click="
          // prettier-ignore
          pickerActive = !pickerActive;
          send('ESC')
        "
        class="bg-white border-gray-800 border-opacity-25 padding-box rounded border-2 shadow-lg cursor-pointer hover:bg-yellow-100 transition-all duration-200"
      >
        <font-awesome-icon class="text-lgtext-gray-700" icon="layer-group" />
      </div>
    </div>
    <div
      tabindex="0"
      class="findme absolute z-30 mt-20"
      role="button"
      aria-controls="help-box"
      aria-label="Visa hjälp"
      @keypress.enter="
        // prettier-ignore
        pickerActive = false;
        send('HELP')
      "
    >
      <div
        class="bg-white border-gray-800 border-opacity-25 padding-box rounded-full border-2 shadow-lg cursor-pointer hover:bg-yellow-100 transition-all duration-200 pl-1"
        @click="
          // prettier-ignore
          pickerActive = false;
          send('HELP')
        "
      >
        <font-awesome-icon class="text-lg text-gray-900 ml-1" icon="info" />
      </div>
    </div>
    <CategoryPicker
      id="category-picker"
      class="picker z-30"
      :aria-hidden="!pickerActive"
      @change="onCategoryFilter($event)"
      :class="{ 'picker-active': pickerActive }"
    ></CategoryPicker>

    <div
      id="help-box"
      class="help inline-block mb-8 rounded-lg p-4 pr-4 sm:mr-8 self-start flex-grow-0 flex-1 shadow-lg bg-opacity-90 max-w-screen bg-yellow-200 border border-yellow-300 space-y-2 z-10"
      :aria-hidden="!context.showHelp"
      :class="{ 'help-active': context.showHelp }"
    >
      <div class="flex space-x-2 items-center">
        <div class="leaflet-control-zoom leaflet-bar leaflet-control shadow-none mx-1">
          <a class="leaflet-control-zoom-in">+</a><a class="leaflet-control-zoom-out">−</a>
        </div>
        <p>Plus- och minusknapparna används för att zooma in och ut på kartan.</p>
      </div>
      <div class="flex space-x-2 items-center">
        <div class="findme flex-shrink-0 transform scale-90 origin-top-left">
          <div
            class="bg-white border-gray-500 border-opacity-25 padding-box rounded border-2 shadow-lg"
          >
            <font-awesome-icon class="text-lgtext-gray-700" icon="crosshairs" />
          </div>
        </div>
        <div>
          Lokalisera-knappen hittar dig på kartan. För att detta skall fungera måste platstjänster
          vara aktiverat på din enhet.
        </div>
      </div>
      <div class="flex space-x-2 items-center">
        <div class="findme flex-shrink-0 transform scale-90 origin-top-left">
          <div
            class="bg-white border-gray-500 border-opacity-25 padding-box rounded border-2 shadow-lg"
          >
            <font-awesome-icon class="text-lgtext-gray-700" icon="layer-group" />
          </div>
        </div>
        <div>
          Lager-knappen styr kartans olika lager av utplacerade nålar. Här styr man alltså själv
          vilka kategorier av nålar man vill se på kartan.
        </div>
      </div>
    </div>
    <div class="relative"></div>
    <div id="map" ref="map" class="w-screen h-screen z-0"></div>
  </div>
</template>

<script>
import L from "leaflet"
import "leaflet-wms-header"
import "leaflet.markercluster"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import find from "lodash/find"
import cloneDeep from "lodash/cloneDeep"

import { getAllPlaces, postPlace, getLibraries } from "../backend"

import iconBlue from "leaflet/dist/images/marker-icon.png"
import iconRed from "../assets/marker-icon-red.png"
import iconBlack from "../assets/marker-icon-black.png"
// import iconOrange from "../assets/marker-icon-orange.png"
import iconGreen from "../assets/marker-icon-green.png"
import iconLibrary from "../assets/library_marker.png"
import iconPurple from "../assets/marker-icon-purple.png"
import iconWhite from "../assets/marker-icon-white.png"
import iconYellow from "../assets/marker-icon-yellow.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import { deparam } from "../util"
import EventBus from "../eventbus"

import { watch } from "vue"

/*
 * L.LabeledIcon is a L.Icon which wraps over another L.Icon, and adds a label to it.
 */
L.LabeledIcon = L.Icon.extend({
  options: {
    html: "",
    className: "leaflet-labeled-icon",
    margin: 5, // Pixels from the icon image
    icon: false, // An instance of L.Icon
    align: "right" // Position of the label relative to the icon, 'left' or 'right'.
  },

  createIcon: function (oldIcon) {
    var div = oldIcon && oldIcon.tagName === "DIV" ? oldIcon : document.createElement("div"),
      options = this.options

    var label = document.createElement("label")

    if (typeof options.html === "string") {
      label.innerHTML = options.html !== false ? options.html : ""
    } else {
      label.appendChild(options.html)
    }
    div.style.position = "relative"
    label.style.position = "absolute"
    label.style.display = "block"
    label.className = options.align

    // Label position will be relative to the icon's anchor pixel.
    /// FIXME: think whether the vertical alignment should line up with the icon,
    ///   with the anchor, or with something else.
    /// Using some CSS like top: calc(-[offset]px - 50%) should work in theory.
    // var iconSize = options.icon.options.iconSize
    // var iconAnchor = options.icon.options.iconAnchor
    // if (options.align === "left") {
    //   label.style.right = iconAnchor[0] + options.margin + "px"
    //   label.style.top = -iconAnchor[1] + "px" // Topline of icon
    // } else {
    //   // 'right'
    //   label.style.left = iconSize[0] - iconAnchor[0] + options.margin + "px"
    //   label.style.top = -iconAnchor[1] + "px" // Topline of icon
    // }

    if (options.icon) {
      div.appendChild(options.icon.createIcon())
    }

    div.appendChild(label)
    this._setIconStyles(div, "icon")

    return div
  },

  createShadow: function (oldIcon) {
    return this.options.icon.createShadow(oldIcon)
  }
})

L.labeledIcon = function (options) {
  return new L.LabeledIcon(options)
}

let makeIcon = (icon, n_articles, className) => {
  // let className = ""
  // if (Number(n_articles) > 1) {
  //   className = "multiple " + "article-num-" + n_articles
  // }
  if (Number(n_articles > 1)) {
    return new L.LabeledIcon({
      html: n_articles.toString(), // Label contents
      className: "leaflet-labeled-icon", // Optional CSS class name
      margin: 0, // Margin from the image ot the label, in pixels
      // iconSize: [10, 10],
      // iconAnchor: [5, 5],
      icon: L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
      }) // An instance of L.Icon
    })
  } else {
    return L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      // iconSize: [10, 10],
      // iconAnchor: [5, 5],
      className: "icon-" + (className == "Linnés resa" ? "linne" : className.toLowerCase())
    })
  }
}

const allPlacesPromise = getAllPlaces()
const allLibraryPromise = getLibraries()

const apiKey = "98707510-7662-3f44-9181-ced9b44462a1"
import { isDev } from "../util"
let bounds
if (isDev) {
  bounds = L.latLngBounds(L.latLng(52.0, 9.3), L.latLng(62, 20))
} else {
  bounds = L.latLngBounds(L.latLng(54.0, 9.3), L.latLng(60, 20))
}

let getPlaceById = (placeid, libraryid, allPlaces) => {
  let places
  if (libraryid) {
    places = allPlaces.filter((place) => place._type == "library")
    return find(places, (place) => place.id == libraryid)
  } else {
    places = allPlaces.filter((place) => place._type != "library")
    return find(places, (place) => place.id == placeid)
  }
}

let map = null
let createdPathname = null

import CategoryPicker from "./CategoryPicker"
import AddPlace from "./AddPlace"
export default {
  name: "Map",
  components: {
    CategoryPicker,
    AddPlace
  },
  data() {
    return {
      isAddMode: false,
      // addMarker: null,
      // successMsg: "",
      // errorMsg: "",
      pickerActive: false
    }
  },
  props: {
    details: {
      type: Number,
      default: null
    }
  },
  watch: {
    async details(val) {
      if (val) {
        // let allPlaces = await allPlacesPromise
        let allPlaces = await this.getFiltered()
        let place = allPlaces.find((place) => place.id == val)
        var popup = L.popup({ offset: [0, -38] })
          .setLatLng(place.latlong)
          .setContent(place.label)
          .openOn(map)

        popup.place = place
        place.marker.getTooltip().setOpacity(0)
      }
    }
  },
  created() {
    createdPathname = window.location.pathname
    EventBus.$on("flyTo", async ({ placeid, zoom, coord, libraryid }) => {
      let bounds = this.map.getBounds()

      if (!coord) {
        let places = await this.getFiltered()
        let place = getPlaceById(placeid, libraryid, places)
        console.log("🚀 ~ file: Map.vue ~ line 302 ~ coord", placeid, libraryid, place)
        coord = place.latlong
      }
      // if zoomed out, zoom in a little. if zoomed in, stay that way.
      if (!zoom) zoom = map.getZoom() > 12 ? map.getZoom() : 12
      this.map.flyToBounds(new L.LatLng(coord.lat, coord.lng).toBounds(100), {
        maxZoom: zoom,
        paddingBottomRight: [window.innerWidth / 2, 0]
      })
    })
    EventBus.$on("resetZoom", async ({ placeid, zoom }) => {
      this.map.setZoom(8)
    })
    EventBus.$on("locate", () => this.locate())
  },

  async mounted() {
    let center = { lng: 13, lat: 58.3 }
    let zoom = 7
    if (createdPathname.endsWith("/blekinge")) {
      center = { lat: 56.22439694633745, lng: 15.42131278663874 }
      zoom = 9
    } else if (createdPathname.endsWith("/halland")) {
      center = { lat: 56.959665611659865, lng: 12.627700567245485 }
      zoom = 9
    } else if (
      createdPathname.endsWith("/g%C3%B6teborg") ||
      createdPathname.endsWith("/goteborg")
    ) {
      center = { lat: 57.66002449251237, lng: 11.952821910381317 }
      zoom = 11
    } else if (
      createdPathname.endsWith("/v%C3%A4rmland") ||
      createdPathname.endsWith("/varmland")
    ) {
    } else if (createdPathname.endsWith("/sk%C3%A5ne") || createdPathname.endsWith("/skane")) {
      center = { lat: 55.9, lng: 13.7 }
      zoom = 9
    }

    const IS_SCREEN_XS = window.innerWidth <= 375

    map = this.map = new L.Map(this.$refs.map, {
      continuousWorld: false,
      center: center,
      zoom: zoom - (IS_SCREEN_XS ? 1 : 0),
      maxBounds: bounds,
      attributionControl: false
    })
    L.control
      .attribution({
        prefix: false
      })
      .addTo(map)
    let setMaxBounds = () => {
      let zoom = map.getZoom()
      let newBounds = L.latLngBounds().extend(bounds)
      if (zoom == 8) {
        newBounds = newBounds.pad(0.5)
      }
      map.setMaxBounds(newBounds)
    }
    let sendMapConstraints = () => {
      let center = map.getCenter()
      this.send({
        type: "MAP_CHANGED",
        center,
        distance: map.distance(center, map.getBounds()._southWest)
      })
    }
    map.on("zoomlevelschange", () => {
      // setMaxBounds()
      sendMapConstraints()
    })
    map.on("popupclose", (a) => {
      console.log("a", a)
      a.popup.place.marker.getTooltip().setOpacity(1)
    })

    map.on("zoomend", () => {
      setTimeout(() => {
        // setMaxBounds()
        sendMapConstraints()
      }, 200)
    })

    if ("no_map" in deparam()) TILE_HOST = ""
    let tiles = new L.TileLayer(
      // `https://litteraturbanken.se/tiles_cache/1.0.0/topowebb/default/3857/{z}/{y}/{x}.png`,
      `https://tile.openstreetmap.org/{z}/{x}/{y}.png`,
      {
        maxZoom: 17,
        minZoom: 6
      }
    ).addTo(map)
    /*
    let tiles = new L.tileLayer.wms(
      `https://litteraturbanken.se/tiles`,
      // `https://litteraturbanken.se/tiles`,
      // `http://localhost:8080/tiles`,
      {
        // let tiles = new L.tileLay er.wms(`http://localhost:2020/tiles`, {
        // let tiles = new L.TileLayer.wmsHeader(
        // `http://maps.lantmateriet.se/topowebb/wms/v1`,
        // {
        layers: "topowebbkartan",
        styles: "topowebbkartan.default",
        // format: "image/png; mode=8bit",
        format: "image/jpeg",
        service: "wms",
        maxZoom: 17,
        // maxNativeZoom: 15,
        minZoom: 6,
        continuousWorld: false,
        // detectRetina: true,
        attribution:
          'Kartbilder &copy; <a tabindex="-1" href="https://www.lantmateriet.se/en/">Lantmäteriet</a>'
      }
      // [{ header: "Authorization", value: "Basic bGl0dDAwMDE6RjJkNDFBN2tpdk0xZTFC" }]
    ).addTo(map)
    */
    map.on("moveend", () => {
      sendMapConstraints()
      console.log("center", map.getCenter())
      console.log("southWest", map.getBounds()._southWest)
      console.log("northEast", map.getBounds()._northEast)
    })

    EventBus.$on("addMarker", ({ latlng }) => this.setAddMarker(latlng))

    map.on("click", (event) => {
      console.log("map click")
      if (this.isAddMode) {
        this.send({
          type: "SET_ADD_MARKER",
          latlng: event.latlng
        })
      } else {
        this.$emit("mapclick", event)
        this.pickerActive = false
      }
      // onMapClick(event)
    })

    // var markerLayer = new L.LayerGroup()
    var markerLayer = (this.markerLayer = L.markerClusterGroup({
      disableClusteringAtZoom: 8,
      maxClusterRadius: 40,
      spiderfyOnMaxZoom: false,
      zoomToBoundsOnClick: false
    }))
    markerLayer.on("clusterclick", (a) => {
      let [first, second, ...rest] = a.layer.getAllChildMarkers()
      let bounds = L.latLngBounds(first.getLatLng(), second.getLatLng())
      for (let marker of rest) {
        bounds.extend(marker.getLatLng())
      }
      map.flyToBounds(bounds)
    })
    map.addLayer(markerLayer)
    // map.addLayer(clusterLayer)
    this.addPlaceMarkers(markerLayer)

    watch(
      () => this.context.categories,
      (newVal, oldVal) => {
        console.log(
          "🚀 ~ file: Map.vue ~ line 426 ~ newVal, oldVal",
          newVal,
          oldVal,
          newVal.length,
          oldVal.length
        )
        if (newVal?.length !== oldVal?.length || !newVal.every((item, i) => item == oldVal[i])) {
          this.addPlaceMarkers(markerLayer)
        }
      }
    )
  },
  methods: {
    async getFiltered() {
      let allPlaces = await allPlacesPromise
      let allLibraries = await allLibraryPromise
      console.log(
        "🚀 ~ file: Map.vue ~ line 444 ~ this.context.categories",
        this.context.categories
      )
      if (this.context.categories) {
        let output = []
        // for (let place of cloneDeep(allPlaces)) {
        for (let place of allPlaces) {
          for (const article of place.articles) {
            article._visible = this.context.categories.includes(article.category)
          }
          // place.articles = place.articles.filter(article => {
          // })
          if (place.articles.some((item) => item._visible)) output.push(place)
        }
        if (this.context.categories.includes("library")) {
          output = [...output, ...allLibraries]
        }
        return output
      } else {
        return [...allLibraries, ...allPlaces]
      }
    },
    onCategoryFilter(categories) {},
    getLibraryMarker(library) {
      let marker = L.marker(library.latlong, {
        icon: makeIcon(iconLibrary, 1, "library"),
        alt: library.name
      })
        .bindTooltip(library.name, {
          direction: "top",
          offset: [0, -40]
        })
        .on("click", (evt) => {
          console.log("onMarkerClick library", evt.containerPoint)
          let library = evt.sourceTarget.place
          // this.send({ type: "DETAILS", placeid: place.id, containerPoint: evt.containerPoint })
          this.send({ type: "DETAILS", libraryid: library.id, containerPoint: evt.containerPoint })
        })
      library.marker = marker
      marker.place = library
      return marker
    },
    getPlaceMarker(place) {
      let visibleArticles = place.articles.filter((article) => article._visible)
      let category = visibleArticles[0]?.category
      if (!category) {
        // console.warn(place)
        return
      }
      let icon = {
        Verk: iconGreen,
        Plats: iconBlue,
        Person: iconPurple,
        Resa: iconWhite,
        "Linnés resa": iconRed
      }[category]

      if (visibleArticles.length == 1) {
        var tooltip = visibleArticles[0].header
        var alt = `Kategori: ${category}. Rubrik: ${tooltip}`
      } else {
        icon = iconYellow
        tooltip = [place.adress, place.namn].filter(Boolean).join(", ")
        alt = `${visibleArticles.length} artiklar. Plats: ${tooltip}`
      }
      place.label = tooltip
      // let latlong = place.latlong
      let marker = L.marker(place.latlong, {
        icon: makeIcon(icon, visibleArticles.length, category),
        alt: alt
      })
        .bindTooltip(tooltip, {
          direction: "top",
          offset: [0, -40]
        })
        .on("click", (evt) => {
          console.log("onMarkerClick", evt.containerPoint)
          let place = evt.sourceTarget.place
          this.send({ type: "DETAILS", placeid: place.id, containerPoint: evt.containerPoint })
        })
      place.marker = marker
      marker.place = place
      return marker
    },
    async addPlaceMarkers(markerLayer) {
      markerLayer.clearLayers()
      let places = await this.getFiltered()
      let marker = null
      // console.groupCollapsed("Category undefined for place")
      for (let place of places) {
        if (place._type == "library") {
          marker = this.getLibraryMarker(place)
        } else {
          marker = this.getPlaceMarker(place)
        }
        if (!marker) continue
        marker.addTo(markerLayer)
      }
      // console.groupEnd()
    },
    onEsc() {
      if (this.isAddMode) {
        this.onAddModeClick()
      }
      this.pickerActive = false
    },

    onAddModeClick() {
      this.isAddMode = !this.isAddMode
      if (!this.isAddMode && this.addMarker) {
        this.addMarker.removeFrom(this.map)
        this.addMarker = null
      }
    },
    locate() {
      console.log("locate")
      let html = '<div class="pulsating-circle"></div>'

      let marker
      map
        .locate({ enableHighAccuracy: true })
        .on("locationfound", (event) => {
          console.log("locationfound", event)
          if (!marker) {
            marker = L.marker(event.latlng, {
              icon: L.divIcon({ html, className: "bg-transparent border-0" }),
              alt: "Min nuvarande plats."
            }).addTo(map)
          } else {
            marker.setLatLng(event.latlng)
          }
          map.flyTo(event.latlng, 12)

          this.send(event)
        })
        .on("locationerror", (event) => {
          console.log("locationerror", event)
          if (marker) map.removeLayer(marker)
          marker = null
        })
    },
    async flyTo(placeId, zoom) {
      // let allPlaces = await this.getFiltered()
      coord = getPlaceById(placeid, libraryid, places).latlong

      return new Promise((resolve, reject) => {
        map.once("zoomend", () => {
          resolve()
        })
        if (zoom) {
          map.flyTo(place.latlong, zoom)
        } else {
          map.flyTo(place.latlong)
        }
      })
    },
    setAddMarker(latlng) {
      if (!this.addMarker) {
        this.addMarker = L.marker(latlng, {
          icon: L.divIcon({
            html: "<div class='transform scale-150 origin-center rotate-45' style='position: relative; top: -8px'>+</div>",
            className: "bg-transparent border-0 add-marker font-sans text-2xl leading-none"
          })
        }).addTo(map)
      } else {
        this.addMarker.setLatLng(latlng)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-mode #map {
  cursor: crosshair;
}
.findme {
  height: 24px;
  width: 34px;
  top: 85px;
  left: 10px;
  & > div {
    padding: 4px 0px 2px 7px;
  }
}
.picker {
  position: absolute;
  top: 207px;
  left: -200px;
  transition: left 300ms;
}
.picker-active {
  left: 10px;
}
.help {
  position: absolute;
  width: 300px;
  top: 207px;
  left: -320px;
  transition: left 300ms;
}
.help-active {
  left: 10px;
}
</style>
